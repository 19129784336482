.pref {
  color: white;
  margin-top: 5%;
  margin-left: 25%;
}

@media screen and (max-width: 800px) {
  .pref {
    margin-left: 0%;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  border-radius: 20px;
  right: 0;
  bottom: 0;
  background-color: #6e6e6e;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  border-radius: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: red;
}

input:focus + .slider {
  /* box-shadow: 0 0 0 4px rgba(21, 156, 228, 0.7); */
  outline: none;
}

input:checked + .slider:before {
  transform: translateX(25px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
