.title {
  color: red;
}

.wrapperAlert {
  text-align: center;
  width: 600px;
  height: auto;
  overflow: hidden;
  border-radius: 12px;
  border: thin solid #ddd;
  margin: 0 auto;
}

@media (max-width: 800px) {
  .wrapperAlert {
    width: 300px;
  }
}

.text {
  font-size: 2.4vmax !important;
}

.topHalf {
  width: 100%;
  color: white;
  overflow: hidden;
  min-height: 250px;
  position: relative;
  padding: 40px 0;
  background: rgb(0, 0, 0);
  background: inherit;
}

.topHalf p {
  margin-bottom: 30px;
}

.topHalf h1 {
  font-size: 2.25rem;
  display: block;
  font-weight: 500;
  letter-spacing: 0.15rem;
  text-shadow: 0 2px rgba(128, 128, 128, 0.6);
}

/* Original Author of Bubbles Animation -- https://codepen.io/Lewitje/pen/BNNJjo */
.bg_bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.float {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.15); /* fade(green, 75%);*/
  bottom: -160px;

  -webkit-animation: square 20s infinite;
  animation: square 20s infinite;

  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}
.float:nth-child(1) {
  left: 10%;
}
.float:nth-child(2) {
  left: 20%;

  width: 80px;
  height: 80px;

  animation-delay: 2s;
  animation-duration: 17s;
}
.float:nth-child(3) {
  left: 25%;
  animation-delay: 4s;
}
.float:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;

  animation-duration: 22s;

  background-color: rgba(white, 0.3); /* fade(white, 25%); */
}
.float:nth-child(5) {
  left: 70%;
}
.float:nth-child(6) {
  left: 80%;
  width: 120px;
  height: 120px;

  animation-delay: 3s;
  background-color: rgba(white, 0.2); /* fade(white, 20%); */
}
.float:nth-child(7) {
  left: 32%;
  width: 160px;
  height: 160px;

  animation-delay: 7s;
}
.float:nth-child(8) {
  left: 55%;
  width: 20px;
  height: 20px;

  animation-delay: 15s;
  animation-duration: 40s;
}
.float:nth-child(9) {
  left: 25%;
  width: 10px;
  height: 10px;

  animation-delay: 2s;
  animation-duration: 40s;
  background-color: rgba(white, 0.3); /*fade(white, 30%);*/
}
.float:nth-child(10) {
  left: 90%;
  width: 160px;
  height: 160px;

  animation-delay: 11s;
}

@-webkit-keyframes square {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-500px) rotate(600deg);
  }
}
@keyframes square {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-500px) rotate(600deg);
  }
}

.bottomHalf {
  align-items: center;
  padding: 35px;
}
.bottomHalf p {
  font-weight: 500;
  font-size: 1.05rem;
  margin-bottom: 20px;
}

.button {
  border: none;
  color: black;
  cursor: pointer;
  padding: 10px 18px;
  font-weight: 700 !important;
  background-color: whitesmoke;
  opacity: 0.9;
}
.button:hover {
  opacity: 0.6;
  background-color: white;
  color: black;
}
.button:focus {
  outline: none;
}

.para {
  color: white;
}

.icon_box {
  text-align: center;
}
.icon {
  font-size: 100px !important;
}
