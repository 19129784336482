.main {
  width: 700px;
  margin: 0 auto;
  margin-top: 7%;
  display: flex;
  background-color: #f2f1f1;
  padding: 10px;
  justify-content: space-around;
}
.pref {
  color: white;
  margin-top: 12%;
  margin-left: 25%;
}

.pref2 {
  color: #0d2436;
  margin-top: 10%;
  margin-left: 20%;
}
@media screen and (max-width: 800px) {
  /* .pref {
    margin-left: 0%;
  } */
  .pref2 {
    margin-left: 0%;
  }
}

.list {
  margin-top: 10px;
}

.list_content {
  margin-bottom: 14px;
  font-size: 18px;
}
@media (max-width: 700px) {
  .list {
    margin-top: 0;
  }
  .list_content {
    font-size: 16px;
  }
}
.plan_btn {
  color: white;
  text-align: center;
  width: 110px;
  background-color: green;
  padding: 4px 5px 4px 6px;
  opacity: 1;
  border: none;
  cursor: default !important;
  border: 1px solid green;
  /* animation: spring 2s;
  animation-timing-function: ease;
  animation-iteration-count: infinite; */
  animation: hop 1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}
.plan_btn:focus {
  outline: none;
}

@keyframes hop {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes spring {
  0% {
    transform: scale(1) translateY(0);
  }
  10% {
    transform: scale(1.2, 0.6);
  }
  30% {
    transform: scale(0.8, 1.1) translateY(-20px);
  }
  50% {
    transform: scale(1) translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.plan_btn:hover {
  opacity: 0.8;
}

.price {
  font-size: 24px;
}

@media (max-width: 800px) {
  .main {
    width: auto;
    margin: 18px;
    margin-top: 10%;
    padding: 0;
  }
  .left_section {
    padding: 8px;
    margin-top: 20px;
  }
  .title {
    font-size: 20px;
  }
  .list_content {
    margin-left: 10px;
    margin-top: 10px;
  }
  .price {
    font-size: 18px;
  }
}

/* SAMRAT EDIT */
@media (max-width: 767px) {
  .pref {
    margin-left: 0px;
  }
}
