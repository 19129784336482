@media screen and (max-width: 800px) {
  .signin {
    overflow: hidden;
  }
}
.signin__bg {
  min-height: 100%;
  min-width: 1024px;
  filter: brightness(65%);
  width: 100%;
  height: auto;
}
@media screen and (max-width: 800px) {
  .signin__bg {
    left: 50%;
    margin-left: -512px;
  }
}
.signin__container {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 800px) {
  .signin__container {
    width: 100%;
  }
}
/* .signin__shadow {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  margin-top: 90px;
  height: auto;
  width: 390px;
  position: absolute;
  top: 0;
} */
.signin__shadow {
  background: #c7335d;
  margin-top: 150px;
  height: auto;
  width: 390px;
  position: absolute;
  top: 0;
}
@media screen and (max-width: 800px) {
  .signin__shadow {
    margin-top: 150px;
    width: 330px;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    margin-bottom: 20px;
  }
}
.signin__title {
  color: white;
  /* margin-left: 60px; */
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 2rem;
  text-align: center;
}
.signin__btn_container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.signin__btn {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
  text-align: center;
  width: 70%;
}
.signin__google_icon {
  font-size: 1.2rem;
  height: 50%;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 8px;
}
.signin__option {
  width: 100%;
  display: flex;
  justify-content: center;
}
.signin__option_newuser {
  color: #fff;
  text-align: center;
  margin-right: 5px;
}
.signin__option_link {
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
  text-decoration: none !important;
}
.signin__option_link:hover {
  text-decoration: underline;
  color: whitesmoke;
  opacity: 0.8;
}

.user_forgot {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.forgot_password {
  color: white;
  text-decoration: none;
  margin-bottom: 30px;
  text-decoration: none !important;
}
.forgot_password:hover {
  text-decoration: underline;
  color: whitesmoke;
  opacity: 0.8;
}

.facebook {
  min-width: 130px;
  width: auto;
  height: 40px;
  line-height: 40px;
  /* padding: 0 22px 0 22px; */
  padding-left: 3vmax;
  font-size: 14.5px;
  background-color: #4267b2;
  font-weight: 600;
  font-family: helvetica;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  margin-bottom: 30px;
}

.google {
  min-width: 130px;
  width: auto;
  height: 40px;
  line-height: 40px;
  padding-left: 3vmax;
  font-size: 14.5px;
  background-color: white;
  font-weight: 600;
  font-family: helvetica;
  color: #494949;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  margin-bottom: 5px;
}

.facebook:hover {
  transform: translateY(-2px);
  transition: 0.1s ease-out;
}
.facebook:focus {
  outline: none !important;
}

.google:hover {
  transform: translateY(-2px);
  transition: 0.1s ease-out;
}

.group {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.group .form_input {
  background: none;
  background-color: #333;
  color: #fff;
  font-size: 14px;
  padding: 10px 10px 10px 7px;
  display: block;
  width: 70%;
  border: none;
  border-radius: 3px;
  margin: 15px 0;
}
.group .form_input:focus {
  outline: none;
}
.group .form_input:focus ~ .form_input_label {
  top: -10px;
  left: 60px;
  font-size: 12px;
  color: white;
}
.group input[type="password"] {
  letter-spacing: 0.3em;
}
.group .form_input_label {
  color: #fff;
  opacity: 1;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 60px;
  top: -10px;
  transition: 300ms ease all;
}
.group .form_input_label.shrink {
  top: -10px;
  left: 60px;
  font-size: 12px;
  color: white;
}

.custom_button {
  min-width: 130px;
  width: auto;
  height: 40px;
  line-height: 40px;
  padding: 0 22px 0 22px;
  font-size: 14.5px;
  background-color: #d66082;
  font-weight: 600;
  font-family: helvetica;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.custom_button:focus {
  outline: none !important;
}
.custom_button:hover {
  transform: translateY(-2px);
  transition: 0.1s ease-out;
}
.custom_button:active {
  transform: translateY(-1px);
}
.custom_button.signin {
  background-color: #fff;
  color: black;
}
.custom_button.signin:hover {
  border: none;
}
.custom_button .custom_button:active {
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.errorMessage {
  color: red;
  margin-top: -20px;
  margin-left: 15%;
  font-size: 13px;
}

.btnerrorMessage {
  margin-top: -15px;
  font-size: 14px;
  color: red;
}
.rahil {
  position: absolute;
  margin-left: 60%;
  font-size: 17px !important;
  margin-top: 7%;
  color: #fff;
  cursor: pointer;
  opacity: 0.7;
}
@media screen and (max-width: 800px) {
  .rahil {
    position: absolute;
    margin-left: 60%;
    font-size: 17px !important;
    margin-top: 8%;
    color: #fff;
    cursor: pointer;
    opacity: 0.7;
  }
}
.checkbox {
  margin-left: 60px;
  color: #fff;
  opacity: 0.7;
}
/* a {
  text-decoration: underline;
  color: #fff;
  font-weight: 800;
} */