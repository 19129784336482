.heads {
  color: azure;
  font-weight: 600;
  font-size: 18px;
  margin-left: 4%;
}

.addIcon2 .tooltiptext {
  visibility: hidden;
  width: 110px;
  background-color: #0f171e;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 36%;
  font-size: 12px;
  left: 82%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.addIcon2 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.addIcon2:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.banner {
  display: flex;
  justify-content: inherit;
  flex-wrap: wrap;
  margin: 1% 5% 5% 6%;
}
.bannerContinueCard {
  display: flex;
  justify-content: inherit;
  flex-wrap: wrap;
  margin: 1% 1% 1% 3%;
}
@media screen and (max-width: 800px) {
  .banner {
    display: flex;
    justify-content: inherit;
    flex-wrap: wrap;
    margin: 2% 1% 1% 2%;
  }
  .bannerContinueCard {
    display: flex;
    justify-content: inherit;
    flex-wrap: wrap;
    margin: 2% 1% 1% 2%;
  }
}
.mediaScreen {
  margin-top: 5px;
  z-index: 0;
}
.displayhoverScreenSearchCard {
  display: block;
  height: 110px;
  background: linear-gradient(rgba(27, 37, 48, 0), #1b2530 48px);
  padding: 30px 30px;
  padding-bottom: 20px;
  padding-bottom: 5px;
  margin-top: -45px;
  z-index: 2;
  text-decoration: none;
  color: white;
}
.carouSearchCard {
  width: 280px;
  height: 260px;
  margin-left: 5px;
  margin-top: 10px;
  padding-top: 5px;
}
.carou {
  width: 280px;
  height: 370px;
  margin-left: 5px;
  margin-top: 15px;
  background-size: contain;
  padding-top: 5px;
}
@media (max-width: 800px) {
  .carou {
    height: auto;
    padding-top: 0px;
  }
}

@media (max-width: 800px) {
  .carou {
    width: 160px;
  }
  .carouSearchCard {
    height: auto;
    width: 160px;
  }
}
.carou,
.carouSearchCard :hover {
  cursor: pointer;
}
.contentSec {
  position: absolute;
  bottom: 8px;
  left: 8px;
  right: 8px;
  overflow: hidden;
  padding: 0;
  white-space: normal;
  font-size: 17px;
  line-height: 24px;
  /* -webkit-font-smoothing: antialiased; */
  text-shadow: 1px -1px 0 rgba(0, 0, 0, 0.35);
  color: #fff;
}

.card1 {
  margin-right: 10px;
  transition: 0.5s;
  overflow: hidden;
}
.card1:hover {
  transform: scale(1.1);
  overflow: hidden;
}

@media (max-width: 800px) {
  .card1 {
    margin-right: 0;
  }
  .card1:hover {
    transform: scale(1);
  }
}

.displayhoverScreen {
  display: block;
  height: 230px;
}
@media (max-width: 800px) {
  .displayhoverScreen {
    display: none;
  }
  .displayhoverScreenSearchCard {
    display: none;
  }
}

.card1:hover > .displayhoverScreen {
  display: block;
}

@media (max-width: 800px) {
  .card1:hover > .displayhoverScreen {
    display: none;
  }
  .card1:hover > .displayhoverScreenSearchCard {
    display: none;
  }
}

.image {
  height: 160px;
  border-radius: 4px;
}

.card1:hover > .image {
  border-radius: 0;
}

@media screen and (max-width: 800px) {
  .image {
    width: 160px !important;
    height: 105px;
  }
  .image:hover {
    filter: opacity(0.8);
    width: 160px !important;
  }
  .card1:hover > .image {
    border-radius: 4px;
  }
}

.owl-carousel .owl-nav button.owl-next {
  color: red !important;
  background-color: blue !important;
  font-size: 300px !important;
}

/* New Styles */
.title {
  margin-top: 10px;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
}

.overview {
  margin-top: 5px;
  font-size: 12px;
  text-decoration: none;
}

.displayhoverScreen {
  position: relative;
  background: linear-gradient(rgba(27, 37, 48, 0), #1b2530 48px);
  padding: 30px 30px;
  padding-bottom: 30px;
  padding-bottom: 5px;
  margin-top: -45px;
  z-index: 2;
  text-decoration: none;
  color: white;
}

.hoverHeading {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
}

.playDiv {
  display: flex;
  justify-content: space-between;
  height: 30px;
  width: 65px;
}
.playIcon {
  width: 40px;
}

.playButton {
  color: white;
  transform: scale(1.3);
}

.playButton:hover,
.addIcon:hover {
  color: #ee2828;
  transition: 0.1s ease-in-out;
  outline: none;
}
.addIcon {
  color: white;
}
.addIcon1 .tooltiptext {
  visibility: hidden;
  width: 110px;
  background-color: #0f171e;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 90%;
  font-size: 12px;
  left: 80%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.addIcon1 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.addIcon1:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.playtext {
  text-decoration: none;
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
}
.footerScreen {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 200px;
  margin-bottom: 20px;
}
.runTime {
  color: darkgray;
  font-size: 11px;
  width: 33.3%;
}
.releaseYear {
  font-size: 11px;
  color: gray;
  margin-bottom: 15px;
  width: 33.3%;
}
.messageIcon {
  color: darkgray;
}
.rated {
  border: 1px solid darkgray;
  color: darkgray;
  font-size: 13px;
  font-weight: 600;
  padding: 1px 8px 1px 8px;
  height: 22px;
}

.moreButton {
  color: lightblue;
  font-size: 14px;
  margin-right: 15px;
  cursor: pointer;
  text-decoration: none;
}
.moreButton:hover {
  color: white;
}

.headingBanner {
  color: white;
  font-weight: 600;
  font-size: 19px;
  margin-left: 3.9%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 800px) {
  .headingBanner {
    margin-left: 5%;
    margin-top: 10px;
  }
}

/* Newest Css */

.mediaSection {
  display: none;
}

@media screen and (max-width: 800px) {
  .mediaSection {
    display: inline-block;
    position: absolute;
    bottom: 0%;
    z-index: 99;
    top: 0%;
    right: 1%;
  }
  .iconRemove {
    background-color: #415264;
    border-radius: 50%;
    color: white;
    font-size: 23px !important;
    z-index: 999 !important;
    padding: 4px;
    transition: 0.3s;
  }
  .iconRemove:hover {
    background-color: rgb(185, 10, 10);
  }
}
