@media screen and (max-width: 800px) {
  .signup {
    overflow-x: hidden;
  }
}
.signup__bg {
  min-height: 100%;
  min-width: 1024px;
  filter: brightness(65%);
  width: 100%;
  height: auto;
}
@media screen and (max-width: 800px) {
  .signup__bg {
    left: 50%;
    margin-left: -512px;
  }
}
.signup__container {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 800px) {
  .signup__container {
    width: 100%;
  }
}
.signup__shadow {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
   */
   background: #c7335d;
  margin-top: 150px;
  height: auto;
  width: 390px;
  position: absolute;
  top: 0;
}
@media screen and (max-width: 800px) {
  .signup__shadow {
    margin-top: 150px;
    width: 330px;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    margin-bottom: 20px;
  }
}
.signup__title {
  color: white;
  /* margin-left: 60px; */
  margin-top: 34px;
  margin-bottom: 15px;
  font-size: 2rem;
  text-align: center;
}
@media screen and (max-width: 800px) {
  .signup__title {
    margin-top: 12px;
    margin-bottom: 5px;
  }
}
.signup__btn_container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.signup__btn {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 12px;
  text-align: center;
  width: 68%;
}

.signin__google_icon {
  font-size: 1.2rem;
  height: 50%;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 9px;
}
.signup__option {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.signup__option_newuser {
  color: #fff;
  text-align: center;
  margin-right: 5px;
}
.signup__option_link {
  color: white;
  text-decoration: none;
  margin-bottom: 30px;
  text-decoration: none !important;
}
.signup__option_link:hover {
  text-decoration: underline;
  color: whitesmoke;
  opacity: 0.8;
}

.group {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.group .form_input {
  background: none;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  padding: 10px 10px 10px 7px;
  display: block;
  width: 70%;
  border: none;
  border-radius: 3px;
  margin: 15px 0;
}
.group .form_input:focus {
  outline: none;
}
.group .form_input:focus ~ .form_input_label {
  top: -10px;
  left: 60px;
  font-size: 12px;
  color: white;
}
.group input[type="password"] {
  letter-spacing: 0.3em;
}
.group .form_input_label {
  color: #fff;
  opacity: 1;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: -10px;
  left: 60px;
  transition: 300ms ease all;
}
.group .form_input_label.shrink {
  top: -10px;
  left: 60px;
  font-size: 12px;
  color: white;
}

.custom_button {
  min-width: 130px;
  width: auto;
  height: 40px;
  line-height: 40px;
  padding: 0 22px 0 22px;
  font-size: 14.5px;
  background-color: #d66082;
  font-weight: 600;
  font-family: helvetica;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.custom_button:hover {
  transform: translateY(-2px);
}
.custom_button:active {
  transform: translateY(-1px);
}
.custom_button.signin {
  background-color: #fff;
  color: black;
}
.custom_button.signin:hover {
  border: none;
}
.custom_button .custom_button:active {
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.facebook {
  min-width: 130px;
  width: auto;
  height: 40px;
  line-height: 40px;
  padding: 0 22px 0 22px;
  font-size: 14.5px;
  background-color: #4267b2;
  font-weight: 600;
  font-family: helvetica;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.facebook:hover {
  transform: translateY(-2px);
}

.errorMessage {
  color: red;
  font-size: 14px;

  margin-top: -20px;
  margin-left: 15%;
}

.btnerrorMessage {
  color: yellowgreen;
}
.checkbox {
  margin-left: 60px;
  color: #fff;
  opacity: 0.7;
  margin-right: 60px;
}
