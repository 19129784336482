@media only screen and (min-width:  240px) and (max-width: 767px) {

	html {
		font-size: 52%;
	}

	body {
		font-size: 1.8rem;
	}

	.section {
		padding: 2rem 2rem 0;
	}

	.container {
		max-width: 100%;
	}

	.mobile_hamburger {
	    display: inline-flex;
	    align-items: center;
	    margin-right: 1rem;
	}

	.hamburger_menu, .hamburger_close {
	    width: 3rem;
	    border-radius: 0.5rem;
	    display: flex;
	    flex-wrap: wrap;
	    align-items: center;
	    justify-content: center;
	    border: none;
	    background: var(--e-global-color-white);
	}

	.hamburger_menu span {
	    width: 100%;
	    height: 0.3rem;
	    display: block;
	    margin: 0.28rem 0;
	    background: var(--e-global-color-dark);
	}

	.logo {
		max-width: 10rem;
	}

	.header_search {
		display: none;
	}

	.header_top_right {
		width: 40%;
		margin: 0 0 0 auto;
	}

	.header_top_right ul li {
		font-size: 2.4rem;
		padding: 0 1.5rem;
	}

	.mobile_header_search {
		display: block;
		font-size: 2.4rem;
		color: var(--e-global-color-dark-chocolate);
	}

	.header_bottom {
		width: 100%;
	    height: 100%;
	    max-width: 38rem;
	    position: fixed;
	    z-index: 999;
	    top: 0;
	    left: -100vw;
	    background-color: var(--e-global-color-white);
	    overflow: hidden;
	    padding: 0;
	    -webkit-transition: left .6s cubic-bezier(.6,.1,.68,.53);
	    transition: left .6s cubic-bezier(.6,.1,.68,.53);
	}

	.header_bottom.menu_open {
	    left: 0;
	}

	.menu ul li {
		width: 100%;
		border-bottom: solid .1rem var(--e-global-color-light-color);
	}

	#menu_background_overlay {
	    display: block;
	    position: fixed;
	    z-index: 14;
	    top: 0;
	    left: -110vw;
	    height: 100%;
	    width: 100%;
	    background-color: rgba(51,51,51,.5);
	    -webkit-transition: all .4s ease;
	    transition: all .4s ease;
	    opacity: 1;
	    visibility: visible;
	    padding: 0;
	}

	#menu_background_overlay.menu_overlay_active {
	    left: 0;
	}






	.banner_left_main {
		padding: 0;
	}

	.banner_left_form {
		margin: 0;
	}

	.banner_left_form form {
		width: 100%;
	}

	.banner_hire span {
		display: block;
	}

	.banner_hire a {
		margin: .5rem;
		font-size: 1.6rem;
	}

	.banner_right {
		padding: 3rem;
	}

	.button_with_google_right {
		font-size: 1.7rem;
	}

	.banner_right_form .terms_link {
		font-size: 1.6rem;
	    line-height: 2.8rem;
	}

	.product_type_image {
	    height: auto;
	}

	.footer_top {
		background-size: contain !important;
    	background-position: bottom !important;
	}

	.footer_col {
		max-width: 100%;
		flex:  0 0 100%;
	}

	.footer_menu {
		width: 100%;
	}

	.footer_bottom_menu {
		width: 100%;
		text-align: center;
	}

	.footer_logo {
		margin-bottom: 2rem;
		text-align: center;
	}

	.copyright {
		text-align: center;
	}

	.footer_last_menu ul {
		flex-direction: column;
	}

	.footer_last_menu ul li {
		padding: 1rem 0;
	}

	.footer_menu h4 {
	    font-size: 2.4rem;
	    margin-bottom: 1rem;
	}

	.footer_menu ul li {
	    font-size: 1.6rem;
	    padding: 0.6rem 0;
	}



	.sort_by {
		text-align: center;
	}

	.sort_by span {
		display: inline-block;
	}

	.filter_box {
		width: 100%;
		margin: 0 0 1rem 0;
	}

	.filter_box a {
		width: 100%;
	}

	.product_search {
		clear: both;
	}

	.product_search input, .product_search input:focus {
		width: 100%;
	}

	.product_listing_rightside_content {
		width: 100%;
		padding: 2rem 0;
	}

	.product_listing_contact {
		width: 100%;
		padding: 0;
	}


}



@media only screen and (min-width:  768px) and (max-width: 1023px) {


	html {
		font-size: 65%;
	} 

	



}





/*-- Only Mobile Version --*/



@media only screen and (min-width: 240px) and (max-width: 767px) {

::-webkit-scrollbar {
    display: none;
}

body {
	font-size: 1.8rem;
	color: var(--e-global-color-black);
}

.main_screen {
	width: 100%;
	height: 100vh;
	position: relative;
	padding-bottom: 9.3rem;
	overflow: auto;
}

.inner_page_main_part {
	width: 100%;
	padding: 2rem;
}

.product_details_product_part {
	flex-wrap: wrap;
}

.product_details_product_part_main_left {
	width: 100%;
	top: 0;
	position: relative;
}

.product_details_product_part_image {
	width: 100%;
	height: auto;
	border: none;
}

.product_details_product_part_image .carousel .carousel-inner {
	height: 30rem;
}

.product_details_product_part_image .carousel-item {
	text-align: center;
}

.product_details_product_part_image .carousel-indicators {
	position: relative;
	bottom: 0;
	display: flex;
}

.product_details_product_part_image .carousel-indicators li {
	border: none;
	width: 4rem;
	height: .7rem;
	background: var(--e-global-color-dark-gray);
	margin: 2rem .3rem 0;
	border-radius: 1rem;
}

.product_details_product_part_image .carousel-indicators li.active {
	background: var(--e-global-color-magenta);
}

.product_details_product_part_main_right {
	width: 100%;
	padding: 0;
}

.product_details_product_part_content {
	width: 100%;
	margin-top: 3rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product_details_product_part_content_left {
	width: calc(100% - 6rem);
}

.product_details_product_part_content_left h4 {
	font-size: 2.4rem;
	text-transform: capitalize;
}

.product_details_product_part_content_left .prodcut_price {
	margin-top: 1rem;
}

.product_details_product_part_content_left .prodcut_price h5 {
	font-size: 2.2rem;
	font-weight: 900;
}

.prodcut_shot_content {
	margin-top: 1rem;
	color: var(--e-global-color-dark-gray);
}

.prodcut_shot_content span {
	display: inline-block;
	width: 100%;
	margin-bottom: 1rem;
}

.product_details_product_part_content_right {
	width: 5rem;
}

.add_wishlist {
	width: 5rem !important;
	height: 5rem !important;
	border-radius: .6rem;
}

.add_wishlist i {
	font-size: 2.4rem;
	color: #f00;
}


.product_color_sec {
	width: 100%;
	margin-top: 2rem;
}

.product_color_sec label {
	font-size: 1.8rem;
}

.product_color_list ul {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.product_color_list ul li {
	width: 22%;
	height: 8rem;
}


.prodcut_count {
	width: 100%;
	margin-top: 6rem;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.prodcut_count input {
	height: 6rem;
}

.prodcut_count .count_updown {
	width: 6rem;
}

.prodcut_count .product_quantity_field {
	width: calc(100% - 16rem);
	font-size: 3rem;
}

.cart_n_buynow {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-top: 2rem;
}

.cart_n_buynow .cart_buy_button {
	padding: 2rem 2rem;
    width: 46%;
    background: var(--e-global-color-magenta);
    border: none;
    font-size: 2.2rem;
    color: var(--e-global-color-white);
    border-radius: 0.5rem;
    text-align: center;
}


.prodcut_detail h4 {
	font-size: 2.4rem;
}

.prodcut_detail ul {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.prodcut_detail ul li {
	width: 50%;
	margin-bottom: 2rem;
}

.prodcut_detail ul li:nth-child(even) {
	padding-left: 2rem;
}

.prodcut_detail ul li p {
	font-size: 2rem;
}


.product_detail_more_details a {
	color: var(--e-global-color-magenta);
}

.product_details_product_part_main_left .add_wishlist, 
.product_details_product_part_main_left .cart_n_buynow {
    display: none;
}

.product_details_product_part_main_right .product_details_product_part_content_right, 
.product_details_product_part_main_right .cart_n_buynow {
    display: flex;
}

.similar_product_list_sec {
	white-space: nowrap;
    overflow-x: scroll;
}

.similar_product_list_box {
	width: 16rem;
}

.similar_product_list_box:last-child {
	margin-right: 0;
}

.similar_product_list_heading span {
	font-size: 1.8rem;
}

.similar_product_list_box_content span {
	font-size: 1.6rem;
}


.product_review_top {
	width: 100%;
}

.write_a_review {
	width: 100%;
	margin: 2rem 0;
	text-align: center;
}


.product_review_list_part_user_date {

}


.product_review_list_part_user_date ul li {
	font-size: 1.6rem;
	padding: 0 1.3rem;
}

.product_review_list_part_user_date ul li:last-child {
	padding-right: 0;
	border-left: solid .1rem var(--e-global-color-dark-gray);
}

.product_review_list_part_user_date ul li i {
	margin-right: .6rem;
}



/*--- Product Details Page End ---*/

.cart_page_left, .cart_page_right {
	width: 100%;
}

.product_box_cart_order {
	padding-top: 2rem;
}

/*.cart_page {
	width: 100%;
	background: var(--e-global-color-light-gray);
}

.product_box_cart_sec {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.product_box_cart {
	width: 100%;
	margin-bottom: 2rem;
	border-radius: .8rem;
	background: var(--e-global-color-white);
	border: solid .1rem var(--e-global-color-dark-gray);
}

.product_box_cart_top {
	width: 100%;
	padding: 2rem;
	display: flex;
	border-bottom: solid .1rem var(--e-global-color-dark-gray);
}

.product_box_cart_top_left {
	width: 25%;
}

.product_box_cart_top_right {
	width: 75%;
	padding-left: 2rem;
}

.product_box_cart_top_right_one {
	width: 100%;
}

.product_box_cart_top_right_one h3 {
	font-size: 2.2rem;
	font-weight: 400;
	text-transform: capitalize;
	margin-bottom: .6rem;
}

.product_box_cart_top_right_one span {
	color: var(--e-global-color-dark-gray);
}

.product_box_cart_top_right_bottom {
	width: 100%;
	display: flex;
	margin-top: 2rem;
	align-items: flex-start;
	justify-content: space-between;
}

.product_box_cart_top_right_bottom_left {
	color: var(--e-global-color-dark-gray);
}

.product_box_cart_top_right_bottom_left input {
	width: auto;
	max-width: 5rem;
	text-align: center;
	border: none;
}

.product_box_cart_top_right_bottom_left span {
	font-size: 2rem;
	width: 3rem;
	display: inline-block;
	text-align: center;
}

.product_box_cart_top_right_bottom_right {
	font-size: 1.8rem;
	color: var(--e-global-color-dark-gray);
}

.product_box_cart_bottom {
	width: 100%;
	padding: 2rem;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.product_box_cart_bottom_part {
	font-size: 1.5rem;
	text-transform: uppercase;
}

.product_box_cart_bottom_part a {
	display: flex;
	align-items: center;
	color: var(--e-global-color-dark-gray);
}

.product_box_cart_bottom_part i {
	font-size: 2rem;
	margin-right: 1rem;
}

.move_to_wishlist a i {
	color: var(--e-global-color-magenta);
}

.product_box_cart_order {
	width: 100%;
	margin-top: 2rem;
}

.product_box_cart_order h2 {
	font-size: 2rem;
	font-weight: 700;
	margin-bottom: 1.5rem;
	padding-bottom: 1.5rem;
	text-transform: uppercase;
	border-bottom: solid .1rem var(--e-global-color-dark-gray);
}

.add_discount_code {
	width: 100%;
	display: block;
	padding-bottom: 1.5rem;
	border-bottom: dashed .3rem var(--e-global-color-dark-gray);
}

.add_discount_code a {
	color: var(--e-global-color-dark-gray);
}

.add_discount_code a i {
	margin-right: .5rem;
}

.cart_totals {
	width: 100%;
	margin-top: 2rem;
}

.totals_item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 1.6rem;
    margin-bottom: 1rem;
    color: var(--e-global-color-dark-gray);
}

.totals_item label {
	text-transform: capitalize;
}

.total_amout {
	font-weight: 700;
	color: var(--e-global-color-dark);
}

.checkout_button {
	width: 100%;
	margin-top: 3rem;
}

.proceed_checkout {
	padding: 2rem 2rem;
    width: 100%;
    background: var(--e-global-color-magenta);
    border: none;
    font-size: 2.2rem;
    color: var(--e-global-color-white);
    border-radius: 0.5rem;
    text-align: center;
}

*/
.product_page_product_list .similar_product_list_box .short_ar {
	display:block;
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	border: solid .1rem var(--e-global-color-dark-gray);
	position: absolute;
	top: 1rem;
	left: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.short_ar {
	display:block;
	width: 2.5rem;
	height: 2.5rem;
	/* border-radius: 50%; */
	border: solid .1rem var(--e-global-color-dark-gray);
	/* position: absolute; */
	top: 1rem;
	left: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.banner_left {
    background: linear-gradient(180deg,#f0ede8,#e3dfdc);
    border-radius: 10px;
    /* left: 30px; */
    padding: 3rem;
    position: relative;
    /* top: 26px; */
}








}