.header {
  color: white;
  margin-left: 6%;
  display: flex;
  margin-right: 7.7%;
  justify-content: space-between;
}
.title {
  opacity: 0.6;
  margin-bottom: 0px !important;
}
.line {
  background-color: whitesmoke;
  margin-right: 7.7%;
  margin-left: 6%;
  opacity: 0.6;
}

.dropbtn {
  background-color: #1f2731;
  color: white;
  padding: 6px 19px;
  border-radius: 3px;
  font-size: 16px;
  opacity: 0.7;
  border: none;
}
.dropbtn:focus {
  outline: none;
}

.dropdown {
  display: inline-block;
  cursor: pointer;
}
.dropdown_content {
  display: none;
  position: absolute;
  right: 1%;
  background-color: #1f2731;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown_content a {
  color: white;
  padding: 8px;
  text-decoration: none;
  display: block;
  opacity: 0.7;
}
.dropdown_content :nth-child(1) {
  border-bottom: 1px solid grey;
}

.checkbox1 {
  margin-left: 40%;
}
.checkbox2 {
  margin-left: 44%;
}
@media (max-width: 800px) {
  .checkbox2 {
    margin-left: 44%;
  }
}

.dropdown_content a:hover {
  background-color: #1f2731;
  color: white;
  opacity: 1;
}

.dropdown:hover .dropdown_content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #1f2731;
}
