@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
	scroll-behavior: smooth;
	font-size: 62.5%;
}

*{
	margin: 0;
	padding: 0;
	list-style: none;
}

:root {
	--body-font-family: 'Roboto', sans-serif;
	--e-global-color-white: #fff;
	--e-global-color-dark: #2b3d36;
	--e-global-color-black: #000;
	--e-global-color-magenta: #c7335d;
	--e-global-color-bottom-green: #0d735e;
	--e-global-color-light-gray: #fafafa;
	--e-global-color-dark-gray: #949494;
	--e-global-color-light-chocolate: #e7c1a8;
	--e-global-color-dark-chocolate: #be5108;
	--e-global-color-light-color: #e3dfdc;
	--e-global-color-green: #0d735e;
}

img {
	max-width: 100%;
}

ul, ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

a, a:hover {
	text-decoration: none;
	color: var(--e-global-color-black);
}

input:focus, button:focus {
	outline: none;
}

body {
	font-size: 1.4rem;
	line-height: normal;
	font-weight: 400;
	color: var(--e-global-color-dark);
	font-family:var(--body-font-family);
}

.section {
	position: relative;
	padding: 3rem 0 0;
	clear: both;
	width: 100%;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
}

header, .logo img {
	transition: all .3s ease;
}

.padding_horizontal {
	padding: 0 1rem;
}


header {
	background: var(--e-global-color-white);
}

.logo {
	max-width: 10%;
}

.header_top {
	padding: 1.5rem;
	display: flex;
	align-items: center;
}

.header_search {
	width: 60%;
	display: flex;
	align-items: center;
}

.header_search form {
	width: 100%;
}

.header_search form .search_top {
	display: flex;
	margin: 0;
	border-radius: .2rem;
	overflow: hidden;
	background: var(--e-global-color-light-gray);
	border: solid .15rem var(--e-global-color-dark-gray);
}

.header_search form input {
	width: 90%;
	height: 4rem;
	border: none;
	padding: 0 1rem;
	background: var(--e-global-color-light-gray);
}

.header_search form input::placeholder {
	font-size: 1.3rem;
}

.header_search form input:focus {
	border: none;
	box-shadow: none;
	outline: none;
}

.header_search form button {
	width: 10%;
	font-size: 1.8rem;
	color: var(--e-global-color-dark-chocolate);
	background: var(--e-global-color-light-gray);
}

.header_search form button:hover {
	color: var(--e-global-color-dark-chocolate);
}

.header_search form button:focus {
	box-shadow: none;
	border: none;
	outline: none;
}

.mobile_hamburger, .mobile_header_search, #menu_background_overlay {display: none;}

.header_top_right {
	width: 30%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.header_top_right ul {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.header_top_right ul li {
	padding: 0 1rem;
	font-size: 2rem;
}

.header_top_right ul li a {
	color: var(--e-global-color-dark-chocolate);
}

.header_bottom {

}

.menu {
	padding: 0;
}

.menu ul {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
}

.menu ul li {
	padding: 0;
	font-size: 1.6rem;
}

.menu ul li a {
	padding: 1.5rem 1.5rem;
	border-bottom: solid .4rem transparent;
	display: block;
	font-weight: 500;
}

.menu ul li a:hover {
	border-color: var(--e-global-color-magenta);
}

.menu ul li.active a {
	font-weight: bold;
	border-color: var(--e-global-color-magenta);
}


.footer {
	width: 100%;
}

.footer_top {
	width: 100%;
	padding-top: 3rem;
	background-position: left center !important;
}

.footer_sec h2 {
	font-size: 2.4rem;
	font-weight: bold;
	margin-bottom: 3rem;
	color: var(--e-global-color-black);
}

.footer_menu {
	margin-bottom: 2rem;
	display: inline-block;
}

.footer_menu h4 {
	font-size: 1.8rem;
	margin-bottom: .5rem;
}

.footer_menu ul li {
	font-size: 1.3rem;
	padding: .3rem 0;
}


.footer_bottom {
	width: 100%;
	padding: 2rem 0;
	background: var(--e-global-color-magenta);
}

.footer_logo img {
	max-width: 14rem;
}

.footer_bottom_menu h4 {
	color: var(--e-global-color-white);
}

.footer_bottom_menu ul li {
	font-size: 1.4rem;
}

.footer_bottom_menu ul li a {
	color: var(--e-global-color-white);
}

.downloada_app .footer_menu {
	display: inline-flex;
	flex-wrap: wrap;
}

.downloada_app .footer_menu h4 {
	width: 100%;
}

.downloada_app a {
	float: left;
	max-width: 47%;
	margin-right: 3%;
    border-radius: .6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    overflow: hidden;
	background: var(--e-global-color-black);
}

.copyright {
	color: var(--e-global-color-white);
}

.footer_last_menu ul {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.footer_last_menu ul li {
	padding: 0 1.5rem;
}

.footer_last_menu ul li a {
	color: var(--e-global-color-white);
}



/*-- header part end --*/


.banner {
	width: 100%;
	height: 100%;
}

.banner_left_main {
	padding-right: 0;
}

/* .banner_left {
	padding: 3rem;
	position: relative;
	background: linear-gradient(to bottom, #f0ede8 0%, #e3dfdc 100%);
} */
.banner_left {
    background: linear-gradient(180deg,#f0ede8eb,#e3dfdce0);
    padding: 3rem;
    position: absolute;
    top: 30px;
    left: 30px;
    /* right: 267px; */
    /* margin-right: -26px; */
    border-radius: 10px;
}

.banner_left:before {
	width: 100%;
	height: 100%;
	content: "";
	top: 0;
	left: 0;
	position: absolute;
	/* background: url(https://miragesoftwares.com/html/prelito/images/banner.png) no-repeat; */
	background-position: right;
	background-size: auto 100%;
}
.footer_top{
	background: url(https://miragesoftwares.com/html/prelito/images/footer.jpg) no-repeat;
}
.banner_left_form h1 {
	color: var(--e-global-color-dark);
	font-size: 3rem;
}

.banner_left_form {
	margin-top: 3rem;
	/* background-color: #ffffffad; */
}

.banner_left_form form {
	width: 85%;
}

.button_with_google {
	width: 100%;
	cursor: pointer;
	margin-top: 2rem;
	border-radius: .4rem;
	display: inline-flex;
	background: var(--e-global-color-white);
	border: solid .1rem var(--e-global-color-light-color);
}

.button_with_google_left {
	padding: 1rem 1.5rem;
	position: relative;
}

.button_with_google_left:after {
	width: .1rem;
	height: 60%;
	content: "";
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	background: var(--e-global-color-light-color);
}

.button_with_google_right {
	padding: 1rem 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--e-global-color-dark);
	font-size: 1.4rem;
	font-weight: 500;
}

.button_line {
	width: 100%;
	height: auto;
	margin: 2rem 0;
	position: relative;
	text-align: center;
	text-transform: capitalize;
	color: var(--e-global-color-dark-gray);
}

.button_line:before, .button_line:after {
	width: 43%;
	height: .1rem;
	content: "";
	top: 0;
	bottom: 0;
	margin: auto;
	position: absolute;
	background: var(--e-global-color-light-color);
}

.button_line:before {
	left: 0;
}

.button_line:after {
	right: 0;
}

.banner_left_form form input {
	width: 100%;
	height: 4.5rem;
	border-radius: .4rem;
	padding: .5rem 1rem;
	display: inline-flex;
	background: var(--e-global-color-white);
	border: solid .1rem var(--e-global-color-light-color);
}


.banner_left_form form .top_signin_button {
	width: 100%;
	height: 5rem;
	padding: 1rem;
	border: none;
	margin-top: 1rem;
	border-radius: .4rem;
	color: var(--e-global-color-white);
	background: var(--e-global-color-magenta);
}

.terms_link {
	width: 100%;
	line-height: 2.2rem;
	color: var(--e-global-color-dark);
}

.terms_link a {
	text-decoration: underline;
	font-weight: 500;
}

.banner_hire {
	margin-top: 2.5rem;
}

.banner_hire span {
	display: inline-block;
	margin-right: .5rem;
	font-weight: 500;
	color: var(--e-global-color-dark);
}

.banner_hire a {
	padding: .5rem 1.2rem;
	font-size: 1.4rem;
	border-radius: 2rem;
	margin-right: .5rem;
	display: inline-block;
	text-transform: capitalize;
	color: var(--e-global-color-green);
	border: solid .1rem var(--e-global-color-green);
}


.banner_right {
	background: var(--e-global-color-magenta);
	padding: 7rem 3rem 0;
	color: var(--e-global-color-white);
}

.banner_right_icon {
	margin-bottom: 2rem;
}

.banner_right_icon img {
	width: 4rem;
	margin-right: 1rem;
}

.banner_right_icon span {
	font-size: 2rem;
}

.banner_right h2 {
	font-size: 3rem;
	font-weight: 300;
	margin-bottom: 1.5rem;
	color: var(--e-global-color-white);
}

.banner_right p {
	font-size: 1.8rem;
	line-height: 2.8rem;
	font-weight: 300;
}

.banner_right_form form input {
	border-radius: .2rem;
}

.banner_right_form form .top_signin_button {
	background: #d66082;
}

.banner_right_form .terms_link {
	color: var(--e-global-color-white);
	font-size: 1.3rem;
	line-height: 2.2rem;
}

.banner_right_form .terms_link a {
	color: var(--e-global-color-white);
}


.product_type_heading {
	margin: 2rem 0;
	display: flex;
    align-items: center;
    justify-content: space-between;
}

.product_type_heading h2 {
	font-size: 2.4rem;
	font-weight: 900;
	width: auto;
	float: left;
	color: var(--e-global-color-black);
}

.product_type_heading a {
	font-size: 2rem;
	color: var(--e-global-color-magenta);
}

.product_part {
	margin-bottom: 3rem;
	padding: 0 .7rem;
}

.product_type_image {
	height: 20rem;
}

.product_type_image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.product_type_name {
	padding-top: 1rem;
}

.product_type_name h4 {
	margin-top: 1rem;
	font-size: 2rem;
	font-weight: 400;
	margin: 0;
	text-transform: capitalize;
	color: var(--e-global-color-dark);
}

.latest_stories .product_type_name span {
	font-size: 1.3rem;
	font-weight: 500;
	text-transform: capitalize;
	color: var(--e-global-color-bottom-green);
}

.latest_stories .product_type_name p {
	color: var(--e-global-color-dark);
	font-weight: 500;
	margin-top: .5rem;
}

.browser_photo_style {

}

.photo_style_box {
	width: 100%;
	border-radius: .4rem;
	overflow: hidden;
	box-shadow: 0 .5rem 1rem var(--e-global-color-light-color);
}

.photo_style_box .product_type_image {
	height: 16rem;
}

.photo_style_box .product_type_name {
	padding: 1rem;
	text-align: center;
}

.photo_style_box .product_type_name p {
	margin: 0;
}


.brand_image_box {
	position: relative;
	overflow: hidden;
	border-radius: .3rem;
}

.brand_logo {
	width: 85%;
	height: 7rem;
	position: absolute;
	bottom: 2rem;
	left: 0;
	right: 0;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: .5rem;
	background: var(--e-global-color-white);
}

.brand_logo img {
	max-width: 80%;
	max-height: 80%;
}

.help_buying .product_type_name h4 {
	font-size: 1.6rem;
}



/*----- Home Page End -----*/



.inner_banner {
	padding: 3rem 0;
	color: var(--e-global-color-white);
	background: var(--e-global-color-magenta);
}

.inner_banner h1 {
	margin-bottom: 1rem;
	font-size: 2.2rem;
	font-weight: 600;
}


.sort_by {
	float: right;
	margin: 0 0 0 auto;
}
.sort_by_filter {
    float: left;
    margin: 0 0 auto 0;
}

.sort_by span {
	margin-left: 1rem;
}

.photos_page .product_type_image {
	height: 30rem;
	border-radius: .5rem;
	overflow: hidden;
}

.photos_name {
	width: 100%;
	margin-top: .5rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-transform: capitalize;
}

.photos_name_icon {
	width: 2.5rem;
	height: 2.5rem;
	overflow: hidden;
	border-radius: 50%;
	margin-right: 1rem;
}

.photos_name_icon img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.find_pro {

}

.find_pro_top {
	padding: 0 0 2rem;
	border-bottom: solid .2rem var(--e-global-color-light-color);
}

.filter_box {
	float: left;
	margin-right: 1.5rem;
}

.filter_box a {
	padding: 1.5rem 2rem;
	display: inline-block;
	font-weight: 500;
	border-radius: .4rem;
	color: var(--e-global-color-dark);
	border: solid .2rem var(--e-global-color-light-color);
}

.filter_box a i {
	font-size: 1.4rem;
	margin-left: .5rem;
}

.all_filter a i {
	margin-right: .5rem;
}

.color_green a {
	color: var(--e-global-color-bottom-green);
}



.product_search {

}

.product_search input {
  width: 13rem;
  height: 5.1rem;
  box-sizing: border-box;
  border: .2rem solid var(--e-global-color-light-color);
  border-radius: .4rem;
  font-size: 1.6rem;
  background-color: var(--e-global-color-white);
  background-image: url(https://miragesoftwares.com/html/prelito/images/searchicon.png);
  background-position: 1rem; 
  background-repeat: no-repeat;
  padding: 1.2rem 2rem 1.2rem 4rem;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}

.product_search input:focus {
  width: 35rem;
}

.find_pro_bottom {
	padding: 0;
}

.product_listing {
	padding: 2rem 0;
	border-bottom: solid .2rem var(--e-global-color-light-color);
}

.product_listing_rightside {
	display: flex;
	flex-wrap: wrap;
}

.product_listing_rightside_content {
	width: 70%;
}

.product_listing_rightside_content .photos_name {
	align-items: flex-start;
}

.product_listing_rightside_content .photos_name_icon {
	width: 5rem;
	height: 5rem;
}

.product_listing_rightside_content .photos_name span {
	font-size: 1.8rem;
	text-transform: capitalize;
}

.product_name_rating {

}

.product_rating {
	color: orange;
}

.product_rating b {
	font-size: 1.6rem;
}

.product_listing_rightside_content .product_rating span {
	font-size: 1.4rem;
	color: var(--e-global-color-dark);
}

.product_listing_rightside_content_part {
	width: 100%;
	margin-top: 2rem;
	color: var(--e-global-color-dark-gray);
}

.product_listing_rightside_content p {
	line-height: 2.2rem;
}

.product_listing_rightside_content_user {
	width: 100%;
	margin-top: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product_listing_rightside_content_user h4 {
	font-weight: 400;
}

.product_listing_rightside_content_user a {
	color: var(--e-global-color-bottom-green);
	font-weight: 500;
	text-transform: capitalize;
}

.product_listing_rightside_content_user a i {
	color: var(--e-global-color-bottom-green);
}

.product_listing_contact {
	width: 30%;
	padding: 0 0 0 3rem;
}

.send_message a {
	display: inline-block;
	padding: 1.2rem 1.5rem;
	border-radius: .4rem;
	font-weight: 500;
	font-size: 1.6rem;
	color: var(--e-global-color-bottom-green);
	border: solid .2rem var(--e-global-color-bottom-green);
}

.send_message a i {
	margin-right: .5rem;
}

.product_listing_contact ul {
	margin-top: 1rem;
}

.product_listing_contact ul li {
	padding: 1rem 0;
	display: flex;
	align-items: flex-start;
}

.product_listing_contact ul li i {
	color: var(--e-global-color-dark);
	margin-right: 1rem;
}

.product_listing_contact ul li span {
	color: var(--e-global-color-dark-gray);
	display: inline-block;
}
.send_message a {
	display: inline-block;
	padding: 1.2rem 1.5rem;
	border-radius: .4rem;
	font-weight: 500;
	font-size: 1.6rem;
	color: var(--e-global-color-bottom-green);
	border: solid .2rem var(--e-global-color-bottom-green);
}

.send_message a i {
	margin-right: .5rem;
}

.product_listing_contact ul {
	margin-top: 1rem;
}

.product_listing_contact ul li {
	padding: 1rem 0;
	display: flex;
	align-items: flex-start;
}

.product_listing_contact ul li i {
	color: var(--e-global-color-dark);
	margin-right: 1rem;
}

.product_listing_contact ul li span {
	color: var(--e-global-color-dark-gray);
	display: inline-block;
}

































.inner_page_main_part {
	width: 100%;
	padding: 2rem 0;
}

.product_details_product_part {
	display: flex;
}

.product_details_product_part_main_left {
	width: 40%;
	top: 1rem;
	z-index: 2;
	position: sticky;
	display: flex;
	flex-wrap: wrap;
	align-self: flex-start;
}


.product_details_product_part_image {
	width: 100%;
	height: 40rem;
	position: relative;
	border: solid .1rem var(--e-global-color-dark-gray);
}

.product_details_product_part_image .carousel .carousel-inner {
	height: 100%;
}

.product_details_product_part_image div {
	width: 100%;
	height: 100%;
}

.product_details_product_part_image .carousel-item {
	text-align: center;
}

.product_details_product_part_image .carousel-item img {
	width: auto;
	height: auto;
	max-width: 100%;
	/* max-height: 100%; */
	object-fit: cover;
	object-position: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.product_details_product_part_image .carousel-indicators {
	position: relative;
	bottom: 0;
	display: none;
}

.product_details_product_part_image .carousel-indicators li {
	border: none;
	width: 4rem;
	height: .7rem;
	background: var(--e-global-color-dark-gray);
	margin: 2rem .3rem 0;
	border-radius: 1rem;
}

.product_details_product_part_image .carousel-indicators li.active {
	background: var(--e-global-color-magenta);
}



.product_details_product_part_main_right {
	width: 60%;
	padding: 0 2rem;
}

.product_details_product_part_main_right .product_details_product_part_content_right,
.product_details_product_part_main_right .cart_n_buynow {
	display: none;
}


.product_details_product_part_content {
	width: 100%;
	margin-top: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product_details_product_part_content_left {
	width: calc(100% - 6rem);
}

.product_details_product_part_content_left h4 {
	font-size: 2.4rem;
	text-transform: capitalize;
}

.product_details_product_part_content_left .prodcut_price {
	margin-top: 1rem;
}

.product_details_product_part_content_left .prodcut_price h5 {
	font-size: 2.2rem;
	font-weight: 900;
}

.prodcut_shot_content {
	margin-top: 1rem;
	color: var(--e-global-color-dark-gray);
}

.prodcut_shot_content span {
	display: inline-block;
	width: 100%;
	margin-bottom: 1rem;
}

.product_details_product_part_content_right {
	width: 5rem;
}

.product_details_product_part_image .add_wishlist {
	position: absolute;
  z-index: 9;
  right: 1rem;
  top: 1rem;
}

.add_wishlist {
	cursor: pointer;
	width: 4rem !important;
  height: 4rem !important;
  border-radius: 0.3rem;
  border: solid 0.1rem var(--e-global-color-dark-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--e-global-color-white);
}

.add_wishlist i {
	font-size: 2.4rem;
	color: var(--e-global-color-magenta);
}


.product_color_sec {
	width: 100%;
	margin-top: 2rem;
}

.product_color_sec label {
	color: var(--e-global-color-dark);
	font-size: 1.6rem;
}

.product_color_list ul {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.product_color_list ul li {
	width: 18%;
	height: 10rem;
	margin-right: 1.5rem;
}

.product_color_list ul li a {
	display: block;
	width: 100%;
	height: 100%;
	padding: .5rem;
	border-radius: .5rem;
	border: solid .1rem var(--e-global-color-dark-gray);
	display: flex;
	align-items: center;
}

.prodcut_count {
	width: 100%;
	margin-top: 2rem;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.prodcut_count input {
	height: 5rem;
	background: var(--e-global-color-white);
	border-radius: .5rem;
	border: solid .1rem var(--e-global-color-dark-gray);
	color: var(--e-global-color-dark-gray);
}

.prodcut_count .count_updown {
	width: 5rem;
	font-size: 4rem;
}

.prodcut_count .product_quantity_field {
	width: 10rem;
	text-align: center;
	font-size: 2.4rem;
	margin: 0 1.5rem;
}

.cart_n_buynow {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-top: 2rem;
}

.cart_n_buynow .cart_buy_button {
		padding: 1.6rem 2rem;
    width: 46%;
    background: var(--e-global-color-magenta);
    border: none;
    font-size: 2.2rem;
    color: var(--e-global-color-white);
    border-radius: 0.5rem;
    text-align: center;
}

.prodcut_detail {
	width: 100%;
	display: block;
	margin-top: 3rem;
}

.prodcut_detail h4 {
	font-size: 2.2rem;
	margin-bottom: 2rem;
	text-transform: uppercase;
}

.prodcut_detail ul {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.prodcut_detail ul li {
	width: 50%;
	margin-bottom: 2rem;
}

.prodcut_detail ul li:nth-child(even) {
	padding-left: 2rem;
}

.prodcut_detail ul li span {
	color: var(--e-global-color-dark-gray);
	display: block;
	margin-bottom: .7rem;
	text-transform: capitalize;
}

.prodcut_detail ul li p {
	font-size: 1.6rem;
}

.product_detail_more_details {
	width: 100%;
}

.product_detail_more_details a {
	color: var(--e-global-color-magenta);
}


.product_details_page_under {
	width: 100%;
	padding: 0 2rem;
}

.similar_product_list {
	width: 100%;
	margin-top: 4rem;
}

.similar_product_list_heading {
	width: 100%;
	margin: 0 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.similar_product_list_heading span {
	color: var(--e-global-color-dark-gray);
	font-size: 1.6rem;
}

.similar_product_list_heading a {
	font-size: 2rem;
    color: var(--e-global-color-magenta);
}


.similar_product_list_sec {
	width: 100%;
	padding-bottom: 1rem;
	/*white-space: nowrap;
  overflow-x: scroll;*/
}

.similar_product_list_box {
	width: 15.5%;
	margin-right: 1rem;
	display: inline-block;
}

.similar_product_list_box:last-child {
	margin-right: 0;
}

.similar_product_list_box a {
	width: 100%;
	height: 100%;
}

.similar_product_list_box_image {
	width: 100%;
	height: 15rem;
	border-radius: .4rem;
}

.similar_product_list_box_image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.similar_product_list_box_content {
	width: 100%;
	margin-top: 1rem;
}

.similar_product_list_box_content p {
	margin: 0 0 .4rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.similar_product_list_box_content span {
	font-size: 1.3rem;
	color: var(--e-global-color-dark-gray);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.product_review_sec {
	width: 100%;
	margin-top: 4rem;
	display: flex;
	flex-wrap: wrap;
}

.product_review_top {
	width: 60%;
}

.product_review_top h4 {
	font-size: 2.2rem;
	font-weight: 700;
	color: var(--e-global-color-dark);
}

.product_rating_review {
	width: 100%;
	margin-top: 2rem;
}

.product_ratings {
	width: auto;
	display: inline-block;
	margin-right: 1.5rem;
} 

.product_ratings i {
	font-size: 2.4rem;
	color: var(--e-global-color-dark-gray);
}

.product_review_count {
	width: auto;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
}

.product_review_count h3 {
	font-size: 2.4rem;
	font-weight: 700;
	margin: 0 1.5rem 0 0;
	color: var(--e-global-color-dark);
}

.product_review_count span {
	padding-left: 1.5rem;
	color: var(--e-global-color-dark-gray);
	border-left: solid .1rem var(--e-global-color-dark-gray);
}

.product_rating_quality {
	width: 100%;
	margin-top: 2rem;
}

.product_rating_quality ul li {
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: .5rem;
}

.product_rating_quality ul li span {
	display: inline-block;
	min-width: 13rem;
}

.product_rating_quality ul li i {
	font-size: 3rem;
	margin: 0 .2rem;
	color: var(--e-global-color-dark-gray);
}

.product_rating_quality ul li .rating_point {
	margin-left: 2rem;
	width: auto;
	min-width: auto;
}

.write_a_review {
	width: 40%;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-align: left;
}

.write_a_review a {
	font-weight: 700;
	font-size: 2.4rem;
	color: var(--e-global-color-dark);
}

.product_review_list {
	width: 100%;
}

.product_review_list_part {
	width: 100%;
	padding-bottom: 1.5rem;
	margin-bottom: 2rem;
	border-bottom: solid .1rem var(--e-global-color-dark-gray);
}


.product_review_list_part_top {
	width: 100%;
}

.product_review_list_part_user .photos_name_icon {
	width: 5rem;
	height: 5rem;
}

.product_review_list_part_user .product_name_rating {
	width: 100%;
}

.product_review_list_part_user_bottom {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}


.product_review_list_part_user_date {

}

.product_review_list_part_user_date ul {
	display: flex;
}

.product_review_list_part_user_date ul li {
	color: var(--e-global-color-dark-gray);
	padding: 0 1rem;
}

.product_review_list_part_user_date ul li:last-child {
	padding-right: 0;
	border-left: solid .1rem var(--e-global-color-dark-gray);
}

.product_review_list_part_user_date ul li i {
	margin-right: .6rem;
}

.product_review_list_part_bottom {
	width: 100%;
	margin-top: 1rem;
	line-height: 2.6rem;
}


/*--- Product Details Page End ---*/





.cart_details_sec {
	background: var(--e-global-color-light-gray);
}


.cart_page {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
}

.cart_page_left {
	width: 50%;
}


.product_box_cart_sec {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.product_box_cart {
	width: 100%;
	margin-bottom: 2rem;
	border-radius: .8rem;
	background: var(--e-global-color-white);
	border: solid .1rem var(--e-global-color-dark-gray);
}

.product_box_cart_top {
	width: 100%;
	padding: 2rem;
	display: flex;
	border-bottom: solid .1rem var(--e-global-color-dark-gray);
}

.product_box_cart_top_left {
	width: 25%;
}

.product_box_cart_top_right {
	width: 75%;
	padding-left: 2rem;
}

.product_box_cart_top_right_one {
	width: 100%;
}

.product_box_cart_top_right_one h3 {
	font-size: 2.2rem;
	font-weight: 400;
	text-transform: capitalize;
	margin-bottom: .6rem;
}

.product_box_cart_top_right_one span {
	color: var(--e-global-color-dark-gray);
}

.product_box_cart_top_right_bottom {
	width: 100%;
	display: flex;
	margin-top: 2rem;
	align-items: flex-start;
	justify-content: space-between;
}

.product_box_cart_top_right_bottom_left {
	color: var(--e-global-color-dark-gray);
}

.product_box_cart_top_right_bottom_left input {
	width: auto;
	max-width: 5rem;
	text-align: center;
	border: none;
}

.product_box_cart_top_right_bottom_left span {
	font-size: 2rem;
	width: 3rem;
	display: inline-block;
	text-align: center;
}

.product_box_cart_top_right_bottom_right {
	font-size: 1.8rem;
	color: var(--e-global-color-dark-gray);
}

.product_box_cart_bottom {
	width: 100%;
	padding: 2rem;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.product_box_cart_bottom_part {
	font-size: 1.5rem;
	text-transform: uppercase;
}

.product_box_cart_bottom_part a {
	display: flex;
	align-items: center;
	color: var(--e-global-color-dark-gray);
}

.product_box_cart_bottom_part i {
	font-size: 2rem;
	margin-right: 1rem;
}

.move_to_wishlist a i {
	color: var(--e-global-color-magenta);
}

.cart_page_right {
	width: 40%;
}

.product_box_cart_order {
	width: 100%;
}

.product_box_cart_order h2 {
	font-size: 2rem;
	font-weight: 700;
	margin-bottom: 1.5rem;
	padding-bottom: 1.5rem;
	text-transform: uppercase;
	border-bottom: solid .1rem var(--e-global-color-dark-gray);
}

.add_discount_code {
	width: 100%;
	display: block;
	padding-bottom: 1.5rem;
	border-bottom: dashed .3rem var(--e-global-color-dark-gray);
}

.add_discount_code a {
	color: var(--e-global-color-dark-gray);
}

.add_discount_code a i {
	margin-right: .5rem;
}

.cart_totals {
	width: 100%;
	margin-top: 2rem;
}

.totals_item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 1.6rem;
    margin-bottom: 1rem;
    color: var(--e-global-color-dark-gray);
}

.totals_item label {
	text-transform: capitalize;
}

.total_amout {
	font-weight: 700;
	color: var(--e-global-color-dark);
}

.checkout_button {
	width: 100%;
	margin-top: 3rem;
}

.proceed_checkout {
	padding: 2rem 2rem;
    width: 100%;
    background: var(--e-global-color-magenta);
    border: none;
    font-size: 2.2rem;
    color: var(--e-global-color-white);
    border-radius: 0.5rem;
    text-align: center;
}
svg {
    fill: #c7335d !important;
}
.cart-count {
    position: absolute;
    width: 15px;
    height: 15px;
    line-height: 18px;
    border-radius: 50%;
    background-color: #be5108;
    color: #f5f1f0;
    font-size: 12px;
    top: 20px;
    right: 38px;
    text-align: center;
}
.checkout_page_left {
	background: var(--e-global-color-white);
}

.checkout_page_left .product_box_cart_sec {
	margin-top: 2rem;
}

.checkout_page_left .product_box_cart {
	border: none;
}

.checkout_page_left .product_box_cart:last-child {
	margin-bottom: 0;
	border: none;
}

.checkout_page_left .product_box_cart .product_box_cart_top {
	padding: 3rem 2rem;
}

.checkout_page_left .product_box_cart .product_box_cart_bottom {
	display: none;
}

.checkout_page_label_name {
	width: 100%;
	display: block;
	padding: 1rem 2rem;
	margin-top: 1rem;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.checkout_page_label_name a {
	color: #2874f0;
	font-weight: 500;
}

.checkout_page_label_name label {
	font-size: 1.4rem;
	font-weight: 400;
	margin: 0;
	text-transform: uppercase;
	color: var(--e-global-color-dark-gray);
}



.checkout_shipping {
	width: 100%;
	padding-bottom: 2rem;
	border-bottom: solid .1rem var(--e-global-color-light-color);
}

.shipping_adddress {
	width: 100%;
	padding: 0 2rem;
}



/* .shipping_adddress_edit {
	display: none;
	width: 100%;
} */

.current_location {
	padding: 0 2rem;
	margin-top: 1rem;
}

.current_location .current_location_use {
	padding: 1.4rem 2rem;
  background: var(--e-global-color-magenta);
  border: none;
  font-size: 1.4rem;
  color: var(--e-global-color-white);
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
}

.current_location .current_location_use i {
	font-size: 2rem;
	margin-right: .6rem;
}


.checkout_edit_form {
	width: 100%;
	padding: 0 2rem;
	margin-top: 2rem;
}

.checkout_form {
  display: grid;
  grid-gap: 1rem;
}

.field {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: .1rem solid var(--e-global-color-light-color);
  padding: .5rem;
  border-radius: .2rem;
  margin-bottom: 1rem;
}

.field__label {
  color: var(--e-global-color-dark-gray);
  font-weight: 300;
  text-transform: capitalize;
  margin-bottom: .5rem
}

.field__input {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  font-weight: 500;
  font-size: 1.4rem;
  height: 2rem;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
}
.field:focus-within {
  border-color: #000;
}

.fields {
  display: grid;
  grid-gap: 1rem;
}
.fields--2 {
  grid-template-columns: 1fr 1fr;
}
.fields--3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.button {
  background-color: #000;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
  display: block;
  color: #fff;
  width: 100%;
  padding: 1rem;
  border-radius: 0.25rem;
  border: 0;
  cursor: pointer;
  outline: 0;
}
.button:focus-visible {
  background-color: #333;
}

.check_out_form_button {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.check_out_form_button_save {
	padding: 1.4rem 4rem;
  background: var(--e-global-color-magenta);
  border: none;
  font-size: 1.4rem;
  color: var(--e-global-color-white);
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.check_out_form_button_cancel {
	color: #2874f0;
  font-weight: 500;
  font-size: 1.6rem;
}

.check_out_form_button_cancel:hover {
	color: #2874f0;
}
.old-price {
    text-decoration: line-through;
}
/*-- 03-08-2022 --*/

.inner_banner_image {
	height: 22rem;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	display: flex;
	align-items: center;
	background-color: var(--e-global-color-magenta);
}

.inner_banner_image_heading {

}

.inner_banner_image_heading h1 {
	font-size: 4.4rem;
	font-weight: 900;
	line-height: 5.4rem;
	color: var(--e-global-color-magenta);
	-webkit-text-stroke: .3rem var(--e-global-color-white);
}



.product_page_product_list .similar_product_list_box {
	/* background: var(--e-global-product-bg-gray); */
	background: #e2e2e2;
	padding: 4rem 1.5rem 1.5rem;
	border-radius: .5rem;
	position: relative;
}

.product_page_product_list .similar_product_list_box .similar_product_list_box_image {
	height: 9rem;
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.product_page_product_list .similar_product_list_box_image img {
	width: auto;
	height: auto;
}

.product_page_product_list .similar_product_list_box .short_wishlist {
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	border: solid .1rem var(--e-global-color-dark-gray);
	position: absolute;
	top: 1rem;
	right: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.product_page_product_list .similar_product_list_box .short_ar {
	display: none;
}
.short_ar {
	display: none;
}

.product_page_product_list .similar_product_list_box .short_wishlist i {
	color: transparent;
	-webkit-text-stroke: .1rem var(--e-global-color-magenta);
}

.active_add_wishlist i {
	color: var(--e-global-color-magenta) !important;
	-webkit-text-stroke: .1rem var(--e-global-color-magenta) !important;
}

.prodcut_price {
	width: 100%;
}

.prodcut_price .current_price {
	font-size: 1.4rem;
	font-weight: 700;
	margin-right: 1rem;
	color: var(--e-global-color-black);
}

.prodcut_price .old_price {
	font-size: 1.4rem;
	font-weight: 400;
	margin-right: 1rem;
	text-decoration: line-through;
	color: var(--e-global-color-dark-gray);
}

.top_deal .similar_product_list_box {
	padding: 0;
	width: 19.3%;
	margin-right: .4rem;
	background: transparent;
}

.top_deal .similar_product_list_box .similar_product_list_box_image {
	height: 16.7rem;
}

.top_deal .similar_product_list_box .similar_product_list_box_image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.product_details_similar .similar_product_list_box {
	padding: 0;
	width: 24%;
	overflow: hidden;
	border-radius: .6rem;
}

.product_details_similar .similar_product_list_box .similar_product_list_box_image {
	height: 15rem;
}

.product_details_similar .similar_product_list_box .similar_product_list_box_image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.product_details_similar .similar_product_list_box .similar_product_list_box_content {
	padding: 1rem;
	margin-top: 0;
}

.product_details_similar .similar_product_list_box .similar_product_list_box_content .prodcut_price {
	margin-bottom: .6rem;
}

.product_details_similar .similar_product_list_box .similar_product_list_box_content .prodcut_price span i {
	color: var(--e-global-color-magenta);
}

.product_details_similar .similar_product_list_box .similar_product_list_box_content .prodcut_price span.flat_off {
	font-weight: 700;
	font-size: 1.4rem !important;
	margin-left: 1rem;
}


.my_account_page {

}

.my_account_page h3 {
	font-size: 1.6rem;
	font-weight: 700;
	margin-bottom: 1rem;
	color: var(--e-global-color-dark);
}

.my_account_main_part {
	width: 100%;
	padding-bottom: 5rem;
	border-top: solid .1rem var(--e-global-border-color);
	display: flex;
	justify-content: flex-start;
}

.my_account_left_part {
	width: 20%;
	padding-top: 1rem;
	border-right: solid .1rem var(--e-global-border-color);
}

.my_account_left_part ul li {
	padding: .7rem 0;
}

.my_account_left_part ul li a {

}




.my_account_right_part {
	padding: 1.5rem 0 1.5rem 1.5rem;
	width: calc(100% - 20%);
}

.my_account_right_part_one {
	width: 100%;
	display: flex;
	margin-bottom: 2rem;
}

.my_account_right_part_one_left {
	width: 60%;
	background: #FCF3F6;
	border: solid .1rem #D9D9D9;
	padding: 3rem 2.5rem;
	border-radius: .6rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.account_profile_image {
	width: 5rem;
	height: 5rem;
	border-radius: 50%;
	margin-right: 1.5rem;
}

.account_profile_details p {
	margin: 0;
}

.my_account_right_part_one_right {
	width: 40%;
	padding-left: 2rem;
}

.my_account_right_part_one_right ul {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.my_account_right_part_one_right ul li {
	width: 47%;
}

.my_account_right_part_one_right ul li:not(:nth-last-child(-1n+2)) {
	margin-bottom: 2rem;
}

.my_account_right_part_one_right ul li a {
	width: 100%;
	padding: 1.2rem;
	display: inline-block;
	border-radius: .6rem;
	text-align: center;
	color: var(--e-global-color-black);
	font-size: 1.6rem;
	border: solid .2rem var(--e-global-color-dark-magenta);
}



.my_account_right_part_two {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 2rem;
}

.prodcut_wishlist_box {
	width: 49%;
	background: #FCF3F6;
	border-radius: .5rem;
	padding: 1.5rem;
}

.prodcut_wishlist_box .similar_product_list_heading span {
	color: var(--e-global-color-black);
	font-size: 1.4rem;
}

.prodcut_wishlist_box .similar_product_list_heading a {
	font-size: 1.4rem;
}

.prodcut_wishlist_box ul {
	display: flex;
}

.prodcut_wishlist_box ul li {
	width: 9rem;
	height: 9rem;
	padding: 1rem;
	border-radius: .5rem;
	background: var(--e-global-color-white);
}

.prodcut_wishlist_box ul li:not(:last-child) {
	margin-right: 1rem;
}

.prodcut_wishlist_box ul li a {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}


.refer_earn_box {
	width: 49%;
	background: #FEDBE7;
	border-radius: .5rem;
	padding: 2.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: var(--e-global-color-black);
}

.refer_earn_box_content {
	width: calc(100% - 7rem);
	padding-right: 1rem;
}

.refer_earn_box_image {
	width: 7rem;
	height: 7rem;
	border-radius: 50%;
	border: solid .2rem var(--e-global-color-white);
}

.refer_earn_box_image img {
	width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.my_account_right_part_three {
	width: 100%;
	background: #FCF3F6;
	border-radius: .5rem;
	padding: 2.5rem;
	margin-bottom: 2rem;
}

.my_account_right_part_three span {
	font-size: 1.2rem;
	color: var(--e-global-color-black);
}

.my_account_right_part_three h5 {
	color: #9A9A9A;
	font-size: 1.6rem;
	font-weight: 400;
	margin: 1rem 0 1.5rem;
}

.my_account_right_part_three ul {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.my_account_right_part_three ul li a {
	padding: 1.2rem;
  display: inline-block;
  border-radius: 0.6rem;
  text-align: center;
  color: var(--e-global-color-black);
  font-size: 1.6rem;
  border: solid 0.2rem var(--e-global-color-dark-magenta);
  background: var(--e-global-color-white);
}

.my_account_right_part_four {
	width: 49%;
	background: #FEDBE7;
	border-radius: .5rem;
	padding: 2rem;
}

.my_account_right_part_four_content {
	width: 100%;
	text-align: left;
	color: var(--e-global-color-black);
}

.my_account_right_part_four_listed_link {
	margin-top: 1rem;
	text-align: center;
}

.my_account_right_part_four_listed_link a {
	font-weight: 700;
	font-size: 1.6rem;
	color: var(--e-global-color-dark-magenta);
}



.my_account_order_page {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	padding-left: 2rem;
}

.my_account_order_page_main {
	width: 100%;
}

.order_heading {
	width: 100%;
	margin-bottom: 2rem;
	font-size: 1.2rem;
	margin-top: 1rem;
}

.order_heading h5 {
	font-size: 1.4rem;
	color: var(--e-global-color-dark);
}

.order_main_sec {
	width: 100%;
	display: flex;
}

.order_main_sec .product_box_cart {
	box-shadow: none;
	width: 70%;
}

.order_deliver_status {
	font-size: 1.2rem;
	color: var(--e-global-color-dark-gray);
	text-transform: none;
}

.order_deliver_status span {
	font-weight: 500;
	color: var(--e-global-color-dark-magenta);
}

.order_track_status {
	color: var(--e-global-color-dark-magenta);
	font-weight: 700;
	font-size: 1.2rem;
	display: flex;
	align-items: center;
	text-transform: none;
}

.order_track_status span {
	cursor: pointer;
}

.order_cancel {
	margin-right: 4rem;
}

.cancel_disable {
	color: var(--e-global-color-dark-gray);
}




.track_part {
	width: 30%;
	padding-left: 2rem;
}

.track_block {
	width: 100%;
	display: flex;
	align-items: center;
	position: relative;
}

.track_block:not(:last-child) {
	padding-bottom: 5rem;
}

.track_block:before {
	width: .4rem;
	height: 100%;
	content: "";
	position: absolute;
	top: 0;
	left: 1.3rem;
	z-index: 1;
	background: var(--e-global-product-bg-gray);
}

.done_track:before {
	background: var(--e-global-color-dark-magenta);
}

.track_dot {
	width: 3rem;
	height: 3rem;
	background: #D9D9D9;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 2;
}

.track_dot span {
	width: 1.5rem;
	height: 1.5rem;
	display: block;
	border-radius: 50%;
	background: var(--e-global-color-dark-magenta);
}

.track_content {
	padding-left: 1.5rem;
}

.track_content span {
	font-size: 1.2rem;
	color: var(--e-global-color-dark-gray);
}

.track_content span b {
	font-family: 700;
	color: var(--e-global-color-dark-magenta);
}



.my_account_coupon_page_main {
	width: 100%;
}

.coupon_sec {
	width: 100%;
}

.coupon_sec .product_box_cart_top_left h1 {
	font-weight: bold;
	text-align: center;
}

.coupon_sec .product_box_cart_top_right {
	display: flex;
	align-items: center;
}

.coupon_sec .product_box_cart_top_right .product_box_cart_top_right_one h4 {
	margin-bottom: 0;
}



.my_account_address_page {
	width: 70%;
}

.my_account_address_page .product_box_cart_top_right {
	padding: 0;
}

.my_account_address_page .product_box_cart_top_right_one h4 {
	color: var(--e-global-color-dark);
}

.my_account_address_page .product_box_cart_top_right_one p {
	font-size: 1.2rem;
	color: var(--e-global-light-gray-color);
}

.address_edit {
	font-weight: 500;
	cursor: pointer;
}

.address_remove span {
	font-weight: 500;
	color: var(--e-global-color-dark-gray);
}

.new_address {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.new_address a {
	font-size: 1.4rem;
	font-weight: 500;
	color: var(--e-global-color-magenta);
}



.find_pro_list_box {
	margin-bottom: 3rem;
	padding-bottom: 2rem;
	border-bottom: solid .1rem #E2E2E2;
}

.find_pro_list_box_image {
	height: 16rem;
	overflow: hidden;
	border-radius: .5rem;
}

.find_pro_list_box a {
	width: 100%;
	height: 100%;
}

.find_pro_list_box img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.find_pro_list_box_contet {
	width: 100%;
	margin-top: 1rem;
	display: inline-block;
}

.find_pro_list_box_contet .photos_name_icon {
	width: 4rem;
	height: 4rem;
}

.find_pro_list_box_contet .product_name_rating {
	display: flex;
	flex-direction: column;
}

.find_pro_list_box_contet .product_name_rating h4 {
	font-size: 1.4rem;
	color: var(--e-global-color-black);
	text-transform: capitalize;
}

.find_pro_list_box_contet .product_name_rating span,
.find_pro_list_box_contet .product_name_rating b {
	color: var(--e-global-light-gray-color);
	font-size: 1rem;
	font-weight: 400;
}






/*--- stories details ---*/


.stories_details_left {
	border-right: solid .1rem var(--e-global-product-bg-gray);	
}

.stories_details_right {

}

.stories_details_left_top {
 padding-bottom: 1rem;
 border-bottom: solid .1rem var(--e-global-product-bg-gray);
}

.stories_details_left_top h2 {
	font-size: 2.2rem;
	line-height: 3rem;
	font-weight: 400;
	text-transform: capitalize;
	color: var(--e-global-color-black);
}

.stories_details_left_top span {
	font-size: 1.2rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-transform: capitalize;
	color: var(--e-global-light-gray-color);
}

.stories_details_left_top .product_name_rating p {
	font-size: 1.2rem;
	margin: 0;
}

.stories_details_left_middle {
	display: flex;
	align-items: flex-start;
	padding: 1.5rem 0;
	border-bottom: solid .1rem var(--e-global-product-bg-gray);
}

.stories_details_left_middle a {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 1rem 1.5rem;
	border-radius: .5rem;
	margin-right: 1rem;
	color: var(--e-global-color-black);
	border: solid .1rem var(--e-global-light-gray-color);	
}

.stories_details_left_middle a i {
	font-size: 2rem;
	margin-right: 1rem;
	color: var(--e-global-color-dark);
}

.stories_details_left_bottom {
	width: 100%;
	padding: 1.5rem 0;
}

.stories_details_left_bottom_list {
	width: 100%;
}

.stories_details_left_bottom_list .find_pro_list_box {
	padding: .5rem 0;
	margin: 0 0 .5rem;
	border: none;
}

.stories_details_right_top {
	margin-bottom: 1.5rem;
}

.stories_details_right_top h2 {
	font-size: 1.6rem;
	line-height: 2.6rem;
	font-weight: 400;
	color: var(--e-global-color-black);
}
/* .similar_product_list_box_image img {
    height: 74px !important;
    width: 120px !important;
} */
/*---- find pro details ----*/


.find_pro_details_left_banner .find_pro_list_box_image {
	height: 30rem;
}

.find_pro_details_left_banner .find_pro_list_box_contet .photos_name_icon {
	width: 6rem;
	height: 6rem;
}

.find_pro_details_left_banner .find_pro_list_box_contet .product_name_rating h4 {
	font-size: 1.6rem;
}

.find_pro_details_left_banner .find_pro_list_box_contet .product_name_rating span, .find_pro_details_left_banner .find_pro_list_box_contet .product_name_rating b {
	font-size: 1.2rem;
}

.find_pro_details_left_banner .find_pro_list_box_contet {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	position: relative;
}

.share_icon {
	display: flex;
	color: var(--e-global-light-gray-color);
	position: absolute;
	right: 0;
	bottom: 0;
}

.share_icon img {
	width: auto;
	height: auto;
	object-fit: contain;
	margin-right: 1rem;
}









.find_pro_details_right {

}

.find_pro_details_right_top h4 {
	font-size: 1.6rem;
	font-weight: 700;
	margin-bottom: 1rem;
	color: var(--e-global-light-gray-color);
}

.find_pro_details_right_top .send_message a {
	width: 100%;
	text-align: center;
	font-weight: 400;
	text-transform: none;
	color: var(--e-global-color-white);
	background: var(--e-global-color-dark-magenta);
	border-color: var(--e-global-color-dark-magenta);
}

.find_pro_details_right_top_box {
	width: 100%;
	height: auto;
	padding: 1rem;
	margin-top: 2rem;
	border-radius: .5rem;
	border: solid .1rem var(--e-global-light-gray-color);
}

.find_pro_details_list {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 1rem 0;
	border-bottom: solid .1rem var(--e-global-color-light-color);
}

.find_pro_details_list:last-child {
	border: none;
}

.find_pro_details_list_icon {
	width: 2rem;
	margin-right: 1rem;
}

.find_pro_details_list_icon {
	max-width: 100%;
}

.find_pro_details_list_text {
	width: calc(100% - 3rem);
	font-size: 1.4rem;
	line-height: 2.2rem;
	color: var(--e-global-color-black);
}

.find_pro_details_list ul {
	width: 100%;
	padding: 0 1rem;
	display: flex;
	align-items: center;
}

.find_pro_details_list ul li {
	margin-right: 1.5rem;
	font-size: 2rem;
}

.find_pro_details_list ul li a {
	color: var(--e-global-color-dark-gray);
}

.find_pro_details_main_tab {
	margin-top: 2rem;
}

.find_pro_tab_list {
	width: 100%;
	padding: 1.5rem 0;
	border-top: solid .1rem var(--e-global-product-bg-gray);
	border-bottom: solid .1rem var(--e-global-product-bg-gray);
}

.find_pro_tab_list ul {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.find_pro_tab_list ul li {
	cursor: pointer;
}

.find_pro_tab_list ul li:not(:last-child) {
	margin-right: 3rem;
}

.find_pro_tab_list ul li.tab_list_active {
	font-weight: 700;
}

.find_pro_tab_box {
	width: 100%;
	margin-top: 2rem;
}

.find_pro_tab_box_part {
	width: 100%;
}

.find_pro_tab_content {
	width: 100%;
	color: #4A4A4A;
	margin-bottom: 2rem;
}

.find_pro_tab_content h4 {
	font-size: 1.6rem;
	font-weight: 700;
	margin-top: 2rem;
	margin-bottom: .5rem;
}

.find_pro_tab_content p {
	margin-bottom: 2rem;
	line-height: 2.4rem;
}

.find_pro_project_part {
	width: 100%;
}

.find_pro_project_part .product_part {
	padding: 0 1rem;
	margin-bottom: 2rem;
}

.find_pro_project_box {
	width: 100%;
	overflow: hidden;
	border-radius: .5rem;
	box-shadow: 0 .1rem 1rem var(--e-global-color-light-color);
}

.find_pro_project_box .product_type_image {
    height: 15rem;
}

.find_pro_project_box .product_type_name {
	padding: 1.5rem;
	font-size: 1.4rem;
}

.find_pro_project_box .product_type_name h5 {
	font-size: 1.4rem;
	font-weight: 600;
	margin-bottom: 0;
}

.find_pro_project_box .product_type_name p {
	margin: 0;
}


.find_pro_details_bottom {
	margin-top: 3rem;
	border-top: solid .1rem var(--e-global-product-bg-gray);	
}

.find_pro_details_bottom_heading {
	padding: 2rem 1.5rem;
}

.find_pro_details_bottom_heading h4 {
	color: #4A4A4A;
	font-weight: 700;
	font-size: 1.8rem;
	margin-bottom: 0;
}

.find_pro_details_bottom .find_pro_project_box {
	border-radius: 0;
	box-shadow: 1rem 1rem 1.2rem -1.2rem var(--e-global-color-light-color);
}

.find_pro_details_bottom .product_type_image {
	height: 10rem;
}


.find_pro_tab2_top {
	width: 100%;
	margin-bottom: 2rem;
	position: relative;
}

.find_pro_tab2_top h2 {
	font-size: 2rem;
	font-weight: 400;
	margin-bottom: 1rem;
}

.find_pro_tab2_top ul li {
	width: 100%;
	padding: .1rem 0;
}

.find_pro_tab_project {
	width: 100%;
}

.tab_project_photo {
	margin-bottom: 2rem;
	padding: 0 1rem;
}

.tab_project_photo a {
	width: 100%;
	height: 22rem;
	border-radius: .5rem;
	overflow: hidden;
	display: block;
}

.tab_project_photo img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}





.filter_heading {
	width: 70%;
	padding-bottom: 1rem;
	border-bottom: solid .1rem var(--e-global-light-gray-color);
}

.filter_heading h4 {
	color: #757575;
	font-weight: 400;
	font-size: 1.6rem;
}

.filter_type {
	width: 100%;
	margin-top: 1.5rem;
}

.filter_type_top {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.5rem;
}

.filter_category {
	width: 70%;
}

.filter_category h4 {
	color: #393939;
	font-weight: 400;
	font-size: 1.6rem;
	text-transform: uppercase;
}

.filter_search {
	width: 2rem;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.filter_type_list {
	width: 100%;
}

.filter_type_list ul li {
	width: 100%;
	display: inline-block;
	margin-bottom: 1.5rem;
}

/*.filter_type_list ul li input {
	width: 2rem;
	height: 2rem;
	border: solid .2rem var(--e-global-light-gray-color);
}*/

.check_label {
  display: block;
  position: relative;
  padding-left: 3rem;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 1.4rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check_label b {
	color: #db3705;
}

.check_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.8rem;
  width: 1.8rem;
  border-radius: .4rem;
  background-color: var(--e-global-color-white);
  border: solid .2rem var(--e-global-light-gray-color);
}

.check_label input:checked ~ .checkmark {
  background-color: var(--e-global-color-magenta);
  border-color: var(--e-global-color-magenta);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check_label input:checked ~ .checkmark:after {
  display: block;
}

.check_label .checkmark:after {
  left: .4rem;
  top: .1rem;
  width: .6rem;
  height: 1rem;
  border: solid var(--e-global-color-white);
  border-width: 0 .2rem .2rem 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.product_list_right .similar_product_list_box {
	width: 32%;
	margin-right: 1%;
	margin-bottom: 2rem;
}

.product_list_right .similar_product_list_box:nth-child(3n+3) {
	margin-right: 0;
}

model-viewer{
	width:100%;
	height:600px;
	background: var(--e-global-color-magenta);
	/* background-image: url("images/1.jpg"); */
}
.thumbs-wrapper.axis-vertical {
    display: none;
}

.nav-link {
	font-size: 1.5rem;
  }
  
  #basic-nav-dropdown {
	font-size: 1.5rem;
  }
  
  #seeMoreLink {
	color: #607d8b;
  }
  
  #seeMoreLink:hover {
	color: black;
	font-weight: 400;
  }
  .dropdown-menu {
	padding: 1em;
  }
  
  .dropdown-item:hover {
	outline: none;
	box-shadow: none;
	background: none;
  }
  .dropdown-item a:hover {
	color: black;
  }
  
  .dropdown-item {
	height: 2em;
    width: 45em;
	padding-left: 0em;
  }
  
  .dropdown-header {
    font-weight: 800;
    font-size: 1.6rem;
    padding-top: 10px;
    padding-left: 0em;
    color: #d66082;
}
.image-gallery-thumbnails {
    display: none;
}