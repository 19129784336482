.responsive_textSection .corner_logo {
  position: relative;
  background-color: rgb(0, 0, 0);
  width: 65px;
  line-height: 65px;
  border-radius: 80%;
  float: left;
  margin-right: 10px;
  margin-left: 15px;
}

@media screen and (max-width: 778px) {
  .bg_cover_responsive img {
    width: 100%;
    margin-top: -20px;
  }

  .filter_url_color {
    color: #3c8cf0;
  }
  .responsive_textSection .corner_logo img {
    width: 60px;
    padding: 2px;
    margin-top: 3px;
    margin-left: 2px;
    margin-bottom: 8px;
  }
  .title_detail {
    position: absolute;
    bottom: 2%;
    color: white;
    font-size: 30px;
    font-weight: 500;
    line-height: normal;
    margin-left: 5%;
  }

  .image_grad2 {
    background: linear-gradient(0deg, #0f171e 0, rgba(15, 23, 30, 0) 50%);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }

  .subtitle_aboutText {
    margin-top: 2px;
    font-size: 13px;
    color: white;
    font-weight: 550;
  }
  .subtitle_text {
    color: whitesmoke;
    font-weight: 550;
    letter-spacing: 0.15px;
    font-size: 13px;
    position: relative;
    top: 2px;
  }
  .hr_1 {
    background-color: gray;
    position: relative;
    top: 0px;
    opacity: 0.6;
  }
  .responsive__actionable {
    width: 100%;
  }
  .responsive__actionable .actionable_icons {
    float: left;
    width: 24%;
    text-align: center;
    cursor: pointer;
  }
  .responsive__actionable .actionable_icons i {
    color: gray;
    position: relative;
    left: 5%;
    top: 10px;
    cursor: pointer;
    font-size: 20px;
  }
  .responsive__actionable .actionable_icons i:hover {
    color: white;
  }
  .icons {
    color: white;
    margin-left: 10px;
  }

  .actionableText {
    font-size: 15px;
    position: relative;
    left: 5%;
    top: 10px;

    color: white;
  }

  .info_text {
    font-size: 14px;
    font-weight: 400;
    color: white;
    line-height: 1.5;
    letter-spacing: 0.15px;
  }
  .meta_info {
    margin-left: 10px;
  }
}
.shfooter .collapse {
  display: inherit;
}
@media (max-width: 767px) {
  /* video{
        height: 20vh !important;
    } */
  .xs {
    height: 20vh !important;
    width: 100%;
  }
  .shfooter ul {
    margin-bottom: 0;
    color: white;
  }

  .shfooter .collapse {
    display: none;
  }

  .shfooter .collapse.show {
    display: block;
  }

  .shfooter .title .fa-chevron-up,
  .shfooter .title[aria-expanded="true"] .fa-chevron-down {
    display: none;
  }

  .shfooter .title[aria-expanded="true"] .fa-chevron-up {
    display: block;
  }

  .shfooter .navbar_toggler {
    display: inline-block;
    padding: 0;
    position: relative;
    top: -160px;
    left: 90%;
    font-weight: 440;
    cursor: pointer;
  }
}
.fa-chevron-down,
.fa-chevron-up {
  font-size: 13px;
  color: gray;
}
.collapse_text {
  font-size: 14px;
  margin: 3%;
  line-height: 25px;
}
/*RESIZESCREEN ANIMATION*/
.fa-angle-double-right {
  animation: rightanime 1s linear infinite;
}

.fa-angle-double-left {
  animation: leftanime 1s linear infinite;
}
@keyframes rightanime {
  50% {
    transform: translateX(10px);
    opacity: 0.5;
  }
  100% {
    transform: translateX(10px);
    opacity: 0;
  }
}
@keyframes leftanime {
  50% {
    transform: translateX(-10px);
    opacity: 0.5;
  }
  100% {
    transform: translateX(-10px);
    opacity: 0;
  }
}

.navsR {
  transition: all 0.4s ease-out;
  position: -webkit-sticky;
  position: sticky;
  background-color: #0f171e;
  -ms-transform: translate(0);
  transform: translate(0);
  z-index: 50;
  height: 60px;
  margin-bottom: 20px;
  top: 0px;
  overflow: auto;
  white-space: nowrap;
  text-align: center;
  display: flex;
  align-items: center;
}
.navsectionR {
  /* box-sizing: border-box;
  max-width: 1600px;
  position: sticky;
  top: 5%;
  width: 100%; */
  margin: 0 auto;
  margin-bottom: 0px;
  padding: 0 48px;
}
.nav2R {
  color: rgb(129, 151, 164);
  text-align: center;
  line-height: 24px;
}

.nav2R span {
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  margin-right: 20px;
}

.nav2R span:hover {
  border-bottom: 2px solid white;
  color: #fff;
  cursor: pointer;
}

.playbutton {
  position: absolute;
  top: 50px;
  left: 40%;
  cursor: pointer;
  color: rgb(87, 84, 84);
}
.playbutton:hover {
  color: white;
}

.plays {
  font-size: 70px !important;
  border-radius: 50%;
  opacity: 0.7;
  background-color: rgb(7, 1, 1);
}

/* Share section */

.modalBody {
  position: relative;
  flex: 1 1 auto;
  text-align: center;
}

.shareIcon {
  font-size: 30px;
  cursor: pointer;
}

.playSection {
  background-color: #f24646;
  padding: 11px;
  margin-top: 10px;
}

.play_button {
  color: white;
  float: left;
  margin-top: -9px;
  font-size: 45px !important;
}

.playtext {
  color: white;
  font-size: 16px;
}
/* 
.socialSection {
  text-align: center;
  color: white;
  padding: 0px;
}

.watchlist {
  font-size: 40px !important;
  background-color: gray;
  border-radius: 50%;
  color: white;
}
.watchlistSection {
  float: right;
}
.watchlistText {
  color: white !important;
}
.shareIco {
  font-size: 40px !important;

  color: white;
} */

.socialSection {
  margin-top: 18px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.watchlistSection {
  color: white;
  width: auto;
  margin-right: 10px;
}

.shareSection {
  color: white;
  width: auto;
  margin-left: 10px;
}

.watchlist {
  font-size: 45px !important;
  color: white;
  padding: 10px;
  background-color: #415264;
  border-radius: 50%;
}

.shareIcon {
  background-color: #415264;
  border-radius: 50%;
  padding: 12px;
  font-size: 45px !important;
}
